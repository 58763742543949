import Vue from 'vue';

import store, { setGlobalRouter } from './store';
import App from './App.vue';

import API from '@/connect/config';
Vue.use(API);

// import VueQrcodeReader from 'vue-qrcode-reader'
// Vue.use(VueQrcodeReader)

//config headers axios
import axios from 'axios';
const refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
axios.interceptors.request.use(
  (config) => {
    // const access_token = await store.dispatch('getAuthorization');
    // console.log(access_token)
    // console.log(config);
    // config.url = `${API}${config.url}`;
    config.headers.Authorization = refresh_token ? refresh_token : '';
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
//map Google
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCXTLGhd3WRBvDLF9xmap16DS7vJPhI2ZA',
    libraries: 'places',
  },
});
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

// VueSweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
require('@core/scss/vue/libs/swiper.scss');
require('@core/scss/core.scss');
require('@/assets/scss/style.scss');

// Global Components
import './global-components';

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

import router from './router';
import { Promise } from 'core-js';
setGlobalRouter(router); // ส่ง router เข้าไปใน setGlobalRouter ที่ได้สร้างไว้

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
