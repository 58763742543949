import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import store from '../store';

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/login_line',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/qrcode',
      name: 'qrcode',
      component: () => import('@/views/Qrcode.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'หน้าหลัก',
        breadcrumb: [
          {
            text: 'หน้าหลัก',
            active: true,
          },
        ],
      },
    },

    //announce
    {
      path: '/announce-information',
      name: 'announceinfor',
      component: () => import('@/views/announce/Announce.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'ประกาศชุมชน',
        breadcrumb: [
          {
            text: 'ประกาศชุมชน',
            active: true,
          },
        ],
      },
    },
    {
      path: '/business-information',
      name: 'businessinfor',
      component: () => import('@/views/announce/Business.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'ข้อมูลธุรกิจ',
        breadcrumb: [
          {
            text: 'ข้อมูลธุรกิจ',
            active: true,
          },
        ],
      },
    },
    {
      path: '/travel-information',
      name: 'travelinfor',
      component: () => import('@/views/announce/Travel.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'ข้อมูลท่องเที่ยว',
        breadcrumb: [
          {
            text: 'ข้อมูลท่องเที่ยว',
            active: true,
          },
        ],
      },
    },
    {
      path: '/service-information',
      name: 'serviceinfor',
      component: () => import('@/views/announce/Serviceinfor.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'ข้อมูลบริการ',
        breadcrumb: [
          {
            text: 'ข้อมูลบริการ',
            active: true,
          },
        ],
      },
    },

    //ticket
    {
      path: '/ticket-from',
      name: 'fromticket',
      component: () => import('@/views/ticket/Fromticket.vue'),
      meta: {
        navActiveLink: true,
        requiresAuth: true,
        pageTitle: null,

        breadcrumb: [
          {
            text: '',
            active: true,
          },
          {
            text: '',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ticket-inform',
      name: 'inform',
      component: () => import('@/views/ticket/Informinfo.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'แจ้งเหตุ',
        breadcrumb: [
          {
            text: 'แจ้งเหตุ',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ticket-maintenance',
      name: 'maintenance',
      component: () => import('@/views/ticket/Maintenanceinfo.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'แจ้งซ่อมบำรุง',
        breadcrumb: [
          {
            text: 'แจ้งซ่อมบำรุง',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ticket-appeal',
      name: 'appeal',
      component: () => import('@/views/ticket/Appealinfo.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'ร้องเรียน',
        breadcrumb: [
          {
            text: 'ร้องเรียน',
            active: true,
          },
        ],
      },
    },

    {
      path: '/meeting/add-meet',
      name: 'addMeet',
      component: () => import('@/views/meeting/AddMeet.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true,
        pageTitle: 'สร้างนัดหมาย',
        breadcrumb: [
          {
            text: 'นัดหมาย',
            active: true,
          },
          {
            text: 'สร้างนัดหมาย',
            active: true,
          },
        ],
      },
    },
    {
      path: '/meeting/history-meet',
      name: 'historyMeet',
      component: () => import('@/views/meeting/HistoryMeet.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'ประวัติการเข้าพบ',
        breadcrumb: [
          {
            text: 'นัดหมาย',
            active: true,
          },
          {
            text: 'ประวัติการเข้าพบ',
            active: true,
          },
        ],
      },
    },
    //editProfile
    {
      path: '/user-edit',
      name: 'user_edit',
      component: () => import('@/views/editProfile.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'แก้ไขโปรไฟล์',
        breadcrumb: [
          {
            text: 'แก้ไขโปรไฟล์',
            active: true,
          },
        ],
      },
    },

    {
      path: '/user-registration',
      name: 'user_regis',
      component: () => import('@/views/UserRegistration.vue'),
      meta: {
        layout: 'full',
        // pageTitle: 'ลงทะเบียนผู้ใช้งาน',
        // breadcrumb: [
        //   {
        //     text: 'ลงทะเบียนผู้ใช้งาน',
        //     active: true,
        //   },
        // ],
      },
    },

    // แผนที่
    {
      path: '/map-single',
      name: 'mapsingle',
      component: () => import('@/views/Mapsingle.vue'),
      meta: {
        requiresAuth: true,
        // layout: 'full',
        pageTitle: 'แผ่นที่',
        breadcrumb: [
          {
            text: '',
            active: true,
          },
          {
            text: 'แผ่นที่',
            active: true,
          },
        ],
      },
    },
    {
      path: '/map-google',
      name: 'mapgoogle',
      component: () => import('@/views/MapGoogle.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/reportofincident',
      name: 'reportofincident',
      component: () => import('@/views/Reportofincident.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'แผ่นที่',
      },
    },
    // {
    //   path: '/map-image',
    //   name: 'mapimage',
    //   component: () => import('@/views/Mapimage.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     pageTitle: 'ภาพเเผ่นที่',
    //     breadcrumb: [
    //       {
    //         text: 'ภาพเเผ่นที่',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/notauthorized',
      name: 'NotAuthorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = JSON.parse(localStorage.getItem('refresh_token'));
  if (to.name !== 'login' && !isAuthenticated) {
    next({ name: 'login' });
  } else {
    // console.log(to);
    // console.log(from);
    // console.log(store.getters.getChang);
    if (to.name == 'fromticket') {
      to.meta.navActiveLink = from.name;
      to.meta.breadcrumb[0].text = from.meta.pageTitle;
      to.meta.breadcrumb[0].to = { name: `${from.name}` };
      to.meta.breadcrumb[0].active = false;
      to.meta.breadcrumb[1].text = `เพิ่มข้อมูล${from.meta.pageTitle}`;
      to.meta.pageTitle = `เพิ่มข้อมูล${from.meta.pageTitle}`;
      console.log(to);
      console.log(from);

      store.dispatch('addPage', to.meta.pageTitle);
    }
    if (to.name == 'mapsingle') {
      to.meta.navActiveLink = from.name;
      to.meta.breadcrumb[0].text = from.meta.pageTitle;
      to.meta.breadcrumb[0].to = { name: `${from.name}` };
      to.meta.breadcrumb[0].active = false;
      store.dispatch('addPage', to.meta.pageTitle);
    }
    if (to.name == 'user_edit') {
      store.dispatch('addPage', to.name);
    }
    next();
  }
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
