import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

import axios from 'axios';
import API from '@/connect/config';

import { jwtDecode } from 'jwt-decode';

Vue.use(Vuex);

let globalRouter = null;

export const setGlobalRouter = (router) => {
  // กำหนดค่า router ให้กับตัวแปร globalRouter
  globalRouter = router;
};

export default new Vuex.Store({
  state: {
    // your state properties
    toastMessage: {},
    distantAPI: {},
    saveStates: [],
    saveChang: '',
    savePage: null,
    save_announce_filter: [],
  },
  mutations: {
    async get_announce_filter(state, payload) {
      state.save_announce_filter = [];
      state.save_announce_filter = payload;
    },
    addPage(state, { payload }) {
      state.savePage = null;
      state.savePage = payload;
    },

    addChang(state, { payload }) {
      state.saveChang = '';
      state.saveChang = payload;
    },
    addStates(state, { payload }) {
      state.saveStates = [];
      state.saveStates.push(payload);
    },
    // your mutations properties
    setDistantAPI(state, { value_1, value_2, value_3 }) {
      state.distantAPI = {
        api: value_1,
        url: value_2,
        access_token: value_3,
      };
    },

    setToastMessage(state, { title, message, variant }) {
      state.toastMessage = {
        title: title,
        message: message,
        variant: variant,
      };
    },
    clearToastMessage(state) {
      state.toastMessage = null;
    },
  },
  actions: {
    async addPage({ commit, dispatch }, payload) {
      commit('addPage', { payload });
    },
    async addChang({ commit, dispatch }, payload) {
      commit('addChang', { payload });
    },
    async addStates({ commit, dispatch }, payload) {
      commit('addStates', { payload });
    },
    imageUrl(image) {
      if (image) {
        const url = window.URL.createObjectURL(image);
        return url;
      }
    },
    async getAuthorization({ commit, dispatch }) {
      const url = `${API}token`;
      const headers = {
        Authorization: localStorage.getItem('refresh_token'),
      };
      const res = await axios.post(url);
      if (res.data.message.access_token) {
        console.log('access');
        return res.data.message.access_token;
      } else {
        console.log('no access');
        globalRouter.push({ name: 'login' });
      }
    },
    async IN_AUTHORIZATION({ commit, dispatch }) {
      const url = `${API}token`;
      const headers = {
        Authorization: localStorage.getItem('refresh_token'),
      };
      const res = await axios.post(url, headers);
      return res.data.message.access_token;
    },
    async getAll({ commit, dispatch }, _URL) {
      let url = '';
      url = `${_URL}&_page=1&_limit=100`;

      const res = await axios.get(url);

      const total = Math.ceil(Number(res.data.message.total) / 100);

      const url_link = [];
      for (let i = 1; i <= total; i++) {
        url_link.push(`${_URL}&_page=${i}&_limit=100_sort=-1`);
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i])).data.message.result];
      }
      //console.log(res_data_);
      return res_data_;
    },
    async get_announce_filter({ commit, dispatch }, _category) {
      //
      // console.log(state.save_announce_filter);
      const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
      let url = '';
      if (_category === 'no_category') {
        url = `${API}announce/${userId}?_page=1&_limit=100&_sort=-1`;
      } else {
        url = `${API}announce/${userId}?category=${_category}&_page=1&_limit=100_sort=-1`;
      }
      const res = await axios.get(url);
      const total = Math.ceil(Number(res.data.message.total) / 100);
      const url_link = [];
      for (let i = 1; i <= total; i++) {
        if (_category === 'no_category') {
          url_link.push(`${API}announce/${userId}?_page=${i}&_limit=100_sort=-1`);
        } else {
          url_link.push(`${API}announce/${userId}?category=${_category}&_page=${i}&_limit=100_sort=-1`);
        }
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i])).data.message.result];
      }
      const { tag } = JSON.parse(localStorage.getItem('profile_visitor'));
      const data = [];
      tag.forEach((el, index) => {
        res_data_.forEach((el2) => {
          el2.tag.forEach((el3) => {
            //    console.log(el);
            //   console.log(el3)
            if (el3 == el) {
              data.push(el2);
            }
          });
        });
      });

      data.sort(function (a, b) {
        if (a.registerDate < b.registerDate) return 1;
        else if (a.registerDate > b.registerDate) return -1;
        else return 0;
      });

      //console.log(getters.save_announce_filter);
      return data;
    },
    async getData({ commit, dispatch }, _url) {
      const access_token = await dispatch('IN_AUTHORIZATION');
      const url = `${API}${_url}`;

      const res = await axios.get(url);

      commit('setDistantAPI', {
        value_1: API,
        value_2: _url,
        value_3: access_token,
      });
      return res.data;
    },

    async getImageURL({ dispatch }, _data) {
      const access_token = await dispatch('IN_AUTHORIZATION');
      const headers = {
        Authorization: access_token,
      };
      return fetch(`${API}view/images/${_data}`, { responseType: 'arraybuffer' })
        .then((r) => r.blob())
        .then((d) => {
          const type = 'image/webp';
          let file = '';
          let url = '';
          file = new File([d], `${_data}`, { type: type });
          url = window.URL.createObjectURL(d);
          // console.log(url);
          return url;
        });
    },
    async getImage({ dispatch }, _data) {
      const access_token = await dispatch('IN_AUTHORIZATION');
      const headers = {
        Authorization: access_token,
      };
      return fetch(`${API}view/images/${_data}`, { responseType: 'arraybuffer' })
        .then((r) => r.blob())
        .then((d) => {
          const type = 'image/webp';
          let file = '';
          let url = '';
          file = new File([d], `${_data}`, { type: type });
          url = window.URL.createObjectURL(d);

          return file;
        });
    },
    async getImageLogo({ dispatch }, _data) {
      console.log(_data);
      const access_token = await dispatch('IN_AUTHORIZATION');
      const headers = {
        Authorization: access_token,
      };
      return fetch(`${API}view/logo/${_data}`, { responseType: 'arraybuffer' })
        .then((r) => r.blob())
        .then((d) => {
          const type = 'image/webp';
          let file = '';
          let url = '';
          file = new File([d], `${_data}`, { type: type });
          url = window.URL.createObjectURL(d);
          console.log(url);
          return url;
        });
    },
    async postData({ commit, dispatch }, { _url, _data }) {
      if (_url === 'login') {
        const url = `${API}${_url}`;
        const res = await axios.post(url, _data);
        return res.data;
      } else {
        const access_token = await dispatch('IN_AUTHORIZATION');
        const url = `${API}${_url}`;

        const res = await axios.post(url, _data);
        return res.data;
      }
    },

    async getJwtDecode({ commit, dispatch }, value) {
      const data = jwtDecode(value);
      return data;
    },
  },
  getters: {
    getStates: (state) => state.saveStates,
    getChang: (state) => state.saveChang,
    getPage: (state) => state.savePage,
    getAnnounce: (state) => state.save_announce_filter,
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
});
